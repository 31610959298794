<template>
  <div v-if="mounted" class="min-h-screen mevcut-container">
    <ClientOnly>
      <CartItemsCart :agent-places="getPlaces" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'

const mounted = ref<boolean>(false)

const { getPlaces, fetchAgentPlace } = usePlacesStore()
const { serverLoad } = useCartStore()
await fetchAgentPlace()
await serverLoad().finally(() => {
  mounted.value = true
})

const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/cart',
    t('cart'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('cart'),
  link: [useSeoCanonical(url + '/cart')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('cart'), url + '/cart')
    ])
  ]
})
</script>
